import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import store from './store';
import { initializeApp } from "./actions/app"

import ArticleHome from './components/ArticleTest/ArticleHome';

import EditForm from './components/Pages/EditForm'

import Account from "./components/Pages/Account";
import Chapters from './components/Pages/Chapters';
import Characters from './components/Pages/Character';
import HomePage from './components/Pages/HomePage';
import Dashboard from './components/Pages/Dashboard';
import Ethnicities from './components/Pages/Ethnicities';
import Events from './components/Pages/Events';
import Items from './components/Pages/Items';
import Language from './components/Pages/Language';
import Locations from './components/Pages/Locations';
import Notes from './components/Pages/Notes';
import Organizations from './components/Pages/Organizations';
import Species from './components/Pages/Species';
import Timelines from './components/Pages/Timelines';
import Titles from './components/Pages/Titles';
import Traditions from './components/Pages/Traditions';
import World from './components/Pages/World';

function App() {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    store.dispatch(initializeApp())
      .then(res => {

        setReady(true);
      });
  }, []);

  const routeList = {
    articles: ArticleHome,
    account: Account,
    chapters: Chapters,
    character: Characters,
    dashboard: Dashboard,
    ethnicities: Ethnicities,
    events: Events,
    items: Items,
    language: Language,
    locations: Locations,
    notes: Notes,
    organizations: Organizations,
    species: Species,
    timelines: Timelines,
    titles: Titles,
    traditions: Traditions,
    world: World,
  }

  return (
    <Provider store={store}>
      {ready ? (
        <Router>
          <Switch>

            <Route path="/" exact component={HomePage} />

            {Object.keys(routeList).map((key)=>(
                <Route key={key} path={`/${key}/:id/edit`} type="edit" component={routeList[key]} />
            ))}
            {Object.keys(routeList).map((key)=>(
                <Route key={key} path={`/${key}/add`} type="add" component={routeList[key]} />
            ))}
            {Object.keys(routeList).map((key)=>(
                <Route key={key} path={`/${key}/:id`} type="view" component={routeList[key]} />
            ))}
            {Object.keys(routeList).map((key)=>(
                <Route key={key} path={`/${key}`} type="list" component={routeList[key]} />
            ))}
          </Switch>
        </Router>
      )
        : (
          <div>Not Ready</div>
        )}

    </Provider>
  );
}

export default App;
