import React, { useState } from "react";
import {Link }from 'react-router-dom'

const DrupalNodeView = (props) => {

  return (
    <div>
        Node View

        <Link to={`${props.location.pathname}/edit`}>Edit</Link>
    </div>
  );

};

export default DrupalNodeView;
