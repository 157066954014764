import C from '../../constants'

const app = (state={ready:false}, action) => {

  switch(action.type) {

    case C.INITIALIZED :
      return {
          ...action.payload,
          ready: true,
        }

    default:
      return state
  }

}

export default app
