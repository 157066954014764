import C from '../../constants'

const fetching = (state=false, action) => {

  switch(action.type) {

    case C.FETCHING :
      return action.payload

    default:
      return state
  }

}

export default fetching
