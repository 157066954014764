const constants = {
  INITIALIZED: 'INITIALIZED',
  FETCHING: 'FETCHING',
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
  SET_APP: 'SET_APP',
  SET_FORMS: 'SET_FORMS',
  SET_WORLD: 'SET_WORLD',
  SET_WORLD_CONTENT_LIST: 'SET_WORLD_CONTENT_LIST',
  SET_WORLD_CONTENT_ITEM: 'SET_WORLD_CONTENT_ITEM',
}

export default constants
