import React, { useState } from "react";
import {NavLink }from 'react-router-dom'

const Header = (props) => {

  return (
    <aside {...props}>
      <nav class="btn-group btn-group-vertical" role="group" aria-label="Button group with nested dropdown">
        <NavLink to="/dashboard" class="btn btn-secondary">Dashboard</NavLink>
        <NavLink to="/world" class="btn btn-secondary">Worlds</NavLink>

        <div class="btn-group btn-group-vertical" role="group">
          <NavLink to="#" id="btnGroupDrop1" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Books
          </NavLink>
          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
            <NavLink class="dropdown-item" to="/chapters">Chapters</NavLink>
            <NavLink class="dropdown-item" to="/notes">Notes</NavLink>
          </div>
        </div>
        <div class="btn-group btn-group-vertical" role="group">
          <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            World Building
          </button>
          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
            <NavLink class="dropdown-item" to="/character">Characters</NavLink>
            <NavLink class="dropdown-item" to="#">Locations</NavLink>
            <NavLink class="dropdown-item" to="#">Events</NavLink>
            <NavLink class="dropdown-item" to="#">Titles</NavLink>
            <NavLink class="dropdown-item" to="#">Organizations</NavLink>
            <NavLink class="dropdown-item" to="#">Species</NavLink>
            <NavLink class="dropdown-item" to="#">Ethnicities</NavLink>
            <NavLink class="dropdown-item" to="#">Language</NavLink>
            <NavLink class="dropdown-item" to="#">Traditions</NavLink>
            <NavLink class="dropdown-item" to="#">Item</NavLink>
          </div>
        </div>
        <NavLink to="#" class="btn btn-secondary">Timelines</NavLink>
      </nav>
    </aside>
  );

};

export default Header;
