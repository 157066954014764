import axios from 'axios';
import C from '../constants';
import ENV from '../env-vars';
import { setWorldContent } from './drupal-api'
import { getAuthClient } from '../utils/auth'

const auth = getAuthClient();

export const initializeApp = () => {
  return async (dispatch, getState) => {

    let status = await dispatch(determineUserLoginStatus());
    let forms = await dispatch(setFormStructure());
    let content = await dispatch(setWorldContent());

    return true;

  }
}

export const determineUserLoginStatus = () => {
  return async (dispatch, getState) => {
    const loggedIn = await auth.isLoggedIn();

    return dispatch(setUserLoginStatus(loggedIn));
  }
}
export const setUserLoginStatus = (loggedIn) => {

  return {type:C.SET_LOGIN_STATUS, payload: loggedIn};
}

export const setFormStructure = () => {
  return async (dispatch, getState) => {

    const forms = await axios
      .get(`/json/forms.json`)
      .then(res => res.data);
    const storage = await axios
      .get(`/json/field-storage.json`)
      .then(res => res.data);
    const config = await axios
      .get(`/json/field-config.json`)
      .then(res => res.data);

    const list = {};

    forms.data.forEach(form => {
      const bundle = form.attributes.bundle;
      const entity = form.attributes.targetEntityType;

      const label = `${entity}--${bundle}`
      const content = {};

      Object.keys(form.attributes.content).forEach((field)=>{
        let fieldDetails = {...form.attributes.content[field]};

        // Merge storage.
        storage.data.forEach((storage)=>{

          if (storage.attributes.field_name == field && storage.attributes.entity_type == entity) {
            fieldDetails = {
              ...fieldDetails,
              ...storage.attributes,
              settings: {
                ...fieldDetails.settings,
                ...storage.attributes.settings,
              },
            }
          }

        })

        // Merge config.
        config.data.forEach((storage)=>{

          if ( storage.attributes.field_name == field &&
                storage.attributes.entity_type == entity &&
                storage.attributes.bundle == bundle) {
            fieldDetails = {
              ...fieldDetails,
              ...storage.attributes,
              settings: {
                ...fieldDetails.settings,
                ...storage.attributes.settings,
              },
            }
          }

        })

        content[field] = fieldDetails;
      })

      list[label] = content;
    });

    dispatch({type: C.SET_FORMS, payload: list})

    return true;

  }
}
