import C from '../../constants'

const forms = (state={}, action) => {

  switch(action.type) {

    case C.SET_FORMS :
      return action.payload

    default:
      return state
  }

}

export default forms
