import React, { useState } from "react";
import LoginForm from '../LoginForm';
import Page from '../Global/Page';

const Homepage = () => {

  return (
    <Page authRequired={true} >

      <div>
          <h1>Home Page</h1>
          <p>details</p>
          <LoginForm />
      </div>
    </Page>
    );

};

export default Homepage;
