import React, { useState } from "react";
import Form from './DrupalForm';
import List from './DrupalNodeList';
import View from './DrupalNodeView';
import { pathToType } from '../../utils/page-functions'


const DrupalNodeReadAndWrite = (props) => {
  const page_type = pathToType(props.match.path);

  return (
    <div>
      <h1>{props.label}</h1>

      {page_type == 'view' ? (<View {...props} />) : null}
      {page_type == 'list' ? (<List {...props} />) : null}
      {page_type == 'add' || page_type == 'edit' ? (<Form {...props} />) : null}
    </div>
  );

};

export default DrupalNodeReadAndWrite;
