import C from '../constants';
import ENV from '../env-vars';
import { getAuthClient } from '../utils/auth';
import { asyncForEach } from '../utils/fetch'
const {DrupalEntity} = require('../utils/DrupalEntity');


const auth = getAuthClient();

export const fetch = (url, options = {} ) => {
  return (dispatch, getState) => {

    const fetchOptions = {
      method: 'GET',
      ...options,
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Cache': 'no-cache',
        ...options.headers,
      }),
    };

    return auth.fetchWithAuthentication(url, fetchOptions)
      .then((response) => response.json())
      .then(data => {

        // If there are any errors display the error and return early.
        if (data.errors && data.errors.length > 0) {
          console.error(`${data.errors[0].title}: ${data.errors[0].detail}`);
          return false;
        }
        console.log('fetch test');
        console.log(data);

        dispatch(setContent(data.data));
      });
  }
}

export const setActiveWorld = (id) => {

  return (dispatch, getState) => {
    const state = getState();

    dispatch({type: C.SET_WORLD, payload: state.world.content['node--world'][id]})
  }
}

export const setWorldContent = (data) => {
  return async (dispatch, getState) => {

    const state = getState();

    if (state.user.loggedIn) {
      await asyncForEach(Object.keys(state.forms), async (type, index, array) => {
        const entityData = type.split('--')
        const url = `/${entityData[0]}/${entityData[1]}`;
        console.log(url);
        const success = await dispatch(fetch(url));
      });
    }

    console.log("done");

  }
}

export const setContent = (data) => {
  return (dispatch, getState) => {


    const datalist = data.map((node)=> {
      console.log(DrupalEntity);
      return new DrupalEntity(
        node
      ).format;
    });
    console.log(datalist);
    dispatch({type: C.SET_WORLD_CONTENT_LIST, payload:datalist})

  }
}
