import C from '../../constants'

const user = (state={}, action) => {

  switch(action.type) {

    case C.SET_LOGIN_STATUS :
      return  {
        ...state,
        loggedIn: action.payload,
      }

    default:
      return state
  }

}

export default user
