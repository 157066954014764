
export class DrupalEntity {
  constructor(info, included = []) {
    this.info = info;
    this.included = included;
    this.attributesIgnoreKeys = ['attributes', 'options', 'settings'];

    this.flattenObject = this.flattenObject.bind(this);
    this.formatRelationships = this.formatRelationships.bind(this);
  }
  get typeDef() {
    return this.getTypeDef(this.info.type);
  }

  get format() {
    return this.getFields(this.info);
  }

  set attrIgnoreKeys(keys) {
    this.attributesIgnoreKeys = keys;
  }

  flattenObject(obj, prefix = '') {
    let flatten = {};

    if (obj instanceof Array) {

    }

    if (obj) {
      Object.keys(obj).forEach(prop => {
        const propertyName = prefix ? `${prefix}_${prop}` : prop;
        if (!(obj[prop] instanceof Array)) {
          flatten[propertyName] = [obj[prop]];
        } else {
          flatten[propertyName] = obj[prop];
        }
      });
    }

    return flatten;
  }

  getTypeDef(type) {
    const self = this;

    return type
      .split('--')
      .map(el =>
        el
          .split(/[\W_]+/g)
          .map(x => self.capitalize(x))
          .join('')
      )
      .join('__');
  }

  formatRelationships(relations) {
    const filterdRelations = {};

    Object.keys(relations).forEach(relation => {
      if (relations[relation].data && relations[relation].data.length) {
        filterdRelations[relation] = relations[relation].data.map(rid =>{

          let entity = this.getDrupalEntity(
            rid
          );
          // entity == undefined means "includes" were not provided
          if (entity === undefined) {
            entity = rid.id;
          }

          return entity;
        });
      } else if (relations[relation].data) {
        let entity = this.getDrupalEntity(
          relations[relation].data
        );
        // entity == undefined means "includes" were not provided
        if (entity === undefined) {
          entity = relations[relation].data.id;
        }

        filterdRelations[relation] = [entity];
      }
    });

    return filterdRelations;
  }

  getFields(entity) {
    const base = {
      entity_id:
        entity.attributes.drupal_internal__nid ||
        entity.attributes.drupal_internal__id ||
        entity.attributes.drupal_internal__mid ||
        entity.attributes.drupal_internal__fid ||
        entity.attributes.drupal_internal__tid,
      id: entity.id,
      type: entity.type,
      typeDef: this.getTypeDef(entity.type),
    };
    const attributes = entity.attributes
      ? this.flattenObject(entity.attributes)
      : {};
    const relations = entity.relationships
      ? this.formatRelationships(entity.relationships)
      : {};
    return {
      ...base,
      ...attributes,
      ...relations,
    };
  }

  getDrupalEntity(entity) {
    const include = this.findIncluded(entity);
    if (include) {
      const dEntity = factory(entity.type);
      return new dEntity(include, this.included).format;
    }
  }

  findIncluded(entity) {
    if (!entity) {
      return undefined;
    }
    return this.included.find(include => entity.id === include.id);
  }

  capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}

class ColonyMenuLinks extends DrupalEntity {
  getTypeDef(type) {
    return 'ColonyMenuLinks';
  }

  getFields(entity) {
    const base = super.getFields(entity);
    return {
      ...base,
      uri: base.link_uri,
      title: base.title_override ? base.title_override : base.title,
    };
  }
}

class File__File extends DrupalEntity { }

const factory = key => {
  switch (key) {
    case 'file_file':
      return File__File;

    default:
      if (key.startsWith('menu_link_content')) {
        return ColonyMenuLinks;
      }
      return DrupalEntity;
  }
};
