import React from "react";
import NodeForm from './NodeForm';

const NodeAdd = ({ onSuccess }) => (
  <NodeForm
    id={null}
    onSuccess={onSuccess}
  />
);

export default NodeAdd;
