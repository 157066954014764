

export const pathToType = (path) => {

    const params = path.split('/');

    console.log(params);
    if (params.length === 4 && params[3] === "edit") {
        return 'edit'
    } else if (params.length === 3 && params[2] === "add") {
        return "add";
    } else if (params.length === 3 && params[2] === ":id") {
        return "view";
    } else {

    }

    return "list"
}
