import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'

const initState = { }

const store = createStore(reducers,initState, compose(applyMiddleware(thunk, /*logger*/), window.devToolsExtension
      ? window.devToolsExtension() : f => f));

export default store
