import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from './Header';
import Aside from './SideNav';

// Utils
import { getAuthClient } from '../../utils/auth';

const auth = getAuthClient();

const Page = (props) => {
  const loggedInStatus = useSelector(state => state.loggedInStatus);
  const activeWorld = useSelector(state => state.world.activeWorld);
  const dispatch = useDispatch();
  const showFullList = true;
  let alternativeRender = null;

  useEffect(() => {

    if (props.authRequired) {
      const state = auth.isLoggedIn().then( res => {

        console.log(res);
      });
    }
  }, [loggedInStatus]); // Verify use is loggedin


  if (props.worldRequired && activeWorld === undefined) {
    alternativeRender = (<div>You need to select a world. </div>);
  }

  return (
    <>
      <Header />
      <Aside />
      <main>
        <div className="container-fluid">
          {alternativeRender ? alternativeRender : props.children}
        </div>
      </main>
    </>
  );

};

export default Page;
