import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import  merge from 'deepmerge';
import DrupalFormField from './DrupalFormField'
import { getAuthClient } from '../../utils/auth';
import env_info from "../../env-vars";

const auth = getAuthClient();

/**
 * Drupal Form
 * @param {*} id
 * @param {*} type
 * @param {*} bundle
 * @param {*} formSettings
 */
const DrupalForm = ({id, type, bundle, formSettings = {}, currentValues}) => {
  const formDefaults = useSelector( state => state.forms[`${type}--${bundle}`] );
  const [isSubmitting, setSubmitting] = useState(false);
  const formFields = formSettings.formOverrides ? merge(formDefaults, formSettings.formOverrides) : formDefaults;

  const [result, setResult] = useState({
    success: null,
    error: null,
    message: '',
  });

  const ignore = [
    'uid',
    'created',
  ];

  const defaultValues = {
  };

  // Set Default Values
  Object.keys(formFields).forEach( (field) => {

    if ( !formFields[field].ignore ) {

      // TODO: Check value, and set value inside array if not already
      // ELSE set undefined array
      if (currentValues && currentValues[field]) {
        defaultValues[field] = currentValues[field] && currentValues[field].length > 0  ? currentValues[field] : [''];
      } else {
        defaultValues[field] = formFields[field].default_value && formFields[field].default_value.length > 0  ? formFields[field].default_value : [''];
      }
    }

  });

  const [values, setValues] = useState(defaultValues);

  const handleInputChange = (name, value) => {
    setValues({...values, [name]: value});
  };

  const handleSubmit = (event) => {
    setSubmitting(true);
    event.preventDefault();

    console.log('handleSubmit');
    console.log(formSettings);

    const fetchUrl = id ? `/${type}/${bundle}/${id}` : `/${type}/${bundle}`;

    let data = {
      "data": {
        "type": `${type}--${bundle}`,
        "attributes": {
        },
        "relationships": {
        }
      }
    };

    const relationships = ["entity_reference"];
    // Split attributes and relationships
    Object.keys(values).forEach((field)=>{

      if (relationships.includes(formFields[field].field_type) ) {
        const reducedValues = values[field].filter(field => !!field)
        data.data.relationships[field] = {data: reducedValues};
      } else {
        data.data.attributes[field] = values[field];
      }
    });

    // If we have an ID that means we're editing an existing node and not
    // creating a new one.
    if (id) {
      // Set the ID in the data we'll send to the API.
      data.data.id = id;
    }

    const fetchOptions = {
      // Use HTTP PATCH for edits, and HTTP POST to create new articles.
      method: id ? 'PATCH' : 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Cache': 'no-cache'
      }),
      body: JSON.stringify(data),
    };

    try {
      auth.fetchWithAuthentication(fetchUrl, fetchOptions)
        .then((response) => response.json())
        .then((data) => {
          // We're done processing.
          setSubmitting(false);

          // If there are any errors display the error and return early.
          if (data.errors && data.errors.length > 0) {
            setResult({
              success: false,
              error: true,
              message: <div className="messages messages--error">{data.errors[0].title}: {data.errors[0].detail}</div>,
            });
            return false;
          }

          // If the request was successful, remove existing form values and
          // display a success message.
          setValues(defaultValues);
          if (data.data.id) {
            setResult({
              success: true,
              message: <div className="messages messages--status">{(id ? 'Updated' : 'Added')}: <em>{data.data.attributes.title}</em></div>,
            });

            if (typeof formSettings.onSuccess === 'function') {
              formSettings.onSuccess(data.data);
            }
          }
        });
    } catch (error) {
      console.log('Error while contacting API', error);
      setSubmitting(false);
    }
  };

  // If the form is currently being processed display a spinner.
  if (isSubmitting) {
    return (
      <div>
        Processing ...
      </div>
    )
  }

  return (
    <div>
      {(result.success || result.error) &&
        <div>
          <h2>{(result.success ? 'Success!' : 'Error')}:</h2>
          {result.message}
        </div>
      }
      <form onSubmit={handleSubmit}>
        { Object.keys(values)
              .sort((a,b)=> formFields[a].weight - formFields[b].weight)
              .map( field_name => (
                <DrupalFormField
                  key={field_name}
                  name={field_name}
                  {...formFields[field_name]}
                  handleInputChange={handleInputChange}
                  values={values[field_name]}/>
                )
              ) }

        <input
          name="submit"
          type="submit"
          value={id ? 'Edit existing node' : 'Add new node'}
        />
      </form>
    </div>
  )
};

export default DrupalForm;
