import React, { useState } from "react";
import LoginForm from '../LoginForm';
import { useDispatch, useSelector } from "react-redux";
import Page from '../Global/Page';
import DrupalNodeReadAndWrite from '../Global/DrupalNodeReadAndWrite';

import { setContent } from '../../actions/drupal-api';

const Traditions = (props) => {
  const dispatch = useDispatch();
  const handleSuccess = (data) => {
    dispatch(setContent([data]));
  }

  const entity_details = {
    label: 'World',
    type: 'node',
    bundle: 'tradition',
    id: props.match.params.id,
    match: props.match,
    location: props.location,
    formSettings: {
      onSuccess: handleSuccess,
      formOverrides: {
        uid: {
          ignore: true,
        },
        created: {
          ignore: true,
        },
        status: {
          ignore: true,
        },
        path: {
          ignore: true,
        },
        promote: {
          ignore: true,
        },
        sticky: {
          ignore: true,
        },
      }
    },
    listSettings: {
      operations: [
        {
          type: "select",
        },
        {
          type: "view",
        },
        {
          type: "edit",
        }
      ]
    }
  }

  entity_details.currentValues = useSelector(state => entity_details.id ? state.world.content[`${entity_details.type}--${entity_details.bundle}`][entity_details.id] : {});


  return (
    <Page authRequired={true} >
      <DrupalNodeReadAndWrite {...entity_details} />
    </Page>
    );

};

export default Traditions;
