import React, { useEffect, useState } from "react";
import {Link }from 'react-router-dom'

import { useDispatch, useSelector } from "react-redux";
import { fetch, setActiveWorld } from '../../actions/drupal-api'

const NodeItem = ({location, id, title, listSettings}) => {
  const dispatch = useDispatch();

  const handleFieldChange = (event) => {
    event.preventDefault();
    const target = event.target.dataset.fieldId;

    dispatch(setActiveWorld(target));
  };

  return (
  <div className="node-list-row">
    <div className="node-list-title">
      <Link to={`${location.pathname}/${id}`}>{title.join(",")}</Link>
    </div>
    <div className="node-list-options">
      { listSettings && listSettings.operations ?
        listSettings.operations.map(
          op => {
            switch (op.type) {
              case "select":
                return (<button type="button"  data-field-id={id} onClick={ handleFieldChange } > select</button>);
              case "view":
                  return (<Link to={`${location.pathname}/${id}`} > View</Link>);
              case "edit":
                return (<Link to={`${location.pathname}/${id}/edit`} > Edit</Link>);
              default:
                return null
            }
          }
        ) : null }
    </div>
  </div>
)};

const NoData = () => (
  <div>No articles found.</div>
);

const DrupalNodeList = (props) => {
  const [filter, setFilter] = useState(null);
  const content = useSelector(state =>  state.world.content[`${props.type}--${props.bundle}`] ? Object.keys(state.world.content[`${props.type}--${props.bundle}`]).map((uuid)=>state.world.content[`${props.type}--${props.bundle}`][uuid]) : []);
  const dispatch = useDispatch();

  useEffect(() => {
    // This should point to your local Drupal instance. Alternatively, for React
    // applications embedded in a Drupal theme or module this could also be set
    // to a relative path.
    const url = `/${props.type}/${props.bundle}?sort=-created`;

    dispatch(fetch(url));

  }, [dispatch, props.type, props.bundle]);

  return (
    <div className="node-list">
      <h2>Site content</h2>

      <div className="">
        <Link to={`${props.location.pathname}/add`}>Add</Link>
      </div>

      {content && content.length ? (
        <>
          <label htmlFor="filter">Type to filter:</label>
          <input
            type="text"
            name="filter"
            placeholder="Start typing ..."
            onChange={(event => setFilter(event.target.value.toLowerCase()))}
          />
          <hr/>
          {
            content.filter((item) => {
              if (!filter) {
                return item;
              }

              if (filter && (item.title.join(",").toLowerCase().includes(filter) || item.body[0].value.toLowerCase().includes(filter))) {
                return item;
              }

              return null
            }).map((item) => <NodeItem key={item.id} location={props.location} {...item} listSettings={props.listSettings}/> )
          }
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default DrupalNodeList;
