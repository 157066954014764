import { combineReducers } from 'redux'
import app from './reducers/app'
import fetching from './reducers/fetching'
import forms from './reducers/forms'
import user from './reducers/user';
import world from './reducers/world'

const reducers = combineReducers({
    app: app,
    // campus: campus,
    // allBuildings: allBuildings,
    // activePage: activePage,
    fetching: fetching,
    user: user,
    forms: forms,
    world: world,
    // modalContent: modalContent,
    // burgerMenu,
});

export default reducers
