import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from '../Global/Page';
import DrupalNodeReadAndWrite from '../Global/DrupalNodeReadAndWrite';

import { setContent  } from '../../actions/drupal-api';


const Character = (props) => {
  const dispatch = useDispatch();
  const activeWorld = useSelector(state => state.world.activeWorld);

  const handleSuccess = (data) => {
    console.log(props);
    dispatch(setContent([data]));
    props.history.goBack();
  }

  const entity_details = {
    label: 'Character',
    type: 'node',
    bundle: 'character',
    id: props.match.params.id,
    match: props.match,
    location: props.location,
    formSettings: {
      onSuccess: handleSuccess,
      formOverrides: {
        uid: {
          ignore: true,
        },
        created: {
          ignore: true,
        },
        status: {
          ignore: true,
        },
        path: {
          ignore: true,
        },
        promote: {
          ignore: true,
        },
        sticky: {
          ignore: true,
        },
        field_world: {
          hidden: true,
        }
      }
    },
    listSettings: {
      operations: [
        {
          type: "view",
        },
        {
          type: "edit",
        }
      ]
    }
  }

  entity_details.currentValues = useSelector(state => entity_details.id ? state.world.content[`${entity_details.type}--${entity_details.bundle}`][entity_details.id] : {});
  if (activeWorld !== undefined) {
    entity_details.formSettings.formOverrides.field_world.default_value = [activeWorld.id];
  }

  return (
    <Page authRequired={true} worldRequired={true} >
      <DrupalNodeReadAndWrite {...entity_details} />
    </Page>
  );

};

export default Character;
