import React, { useEffect, useState, Children, isValidElement, cloneElement } from "react";
import { useDispatch, useSelector } from "react-redux";

const FieldGroup = (props) => {
  const fieldVals = props.values ? [...props.values] : [undefined];

  const cardinality = props.cardinality ? props.cardinality : 1;
  const maxed = cardinality >= fieldVals.length || cardinality !== -1;

  const handleFieldChange = (value, index) => {
    let val = [...fieldVals];
    val[index] = value;

    console.log('handleFieldChange Parent');
    console.log(props.name);
    console.log(val);
    props.handleInputChange(props.name, val);
  };

  const addField = (e) => {
    e.preventDefault();
    props.handleInputChange(props.name, [...fieldVals, undefined]);
  };

  return <div className="form-item">
    {fieldVals.map((value, index) => (
      <div className="form-control-wrapper" key={`${props.name}-${index}`}>
        {cloneElement(props.field, {
          ...props,
          name: `${props.name}-${index}`,
          index: index,
          value: value,
          handleFieldChange: handleFieldChange,
        })}
        {fieldVals.length > 1 ? (<button> Remove</button>) : null}
      </div>

    ))}
    {!maxed ? (<button onClick={addField}>Add</button>) : (null)}
  </div>
};

const StringTextfield = (props) => {

  const handleFieldChange = (event) => {
    const { value } = event.target;
    const target = event.target.getAttribute('data-field-index');
    props.handleFieldChange(value, target);
  };
  return (
    <input
      className="form-control"
      data-field-index={props.index}
      name={props.name}
      type="text"
      value={props.value}
      placeholder={props.settings.placeholder}
      onChange={handleFieldChange}
    />
  )
};

const EntityReferenceAutocomplete = (props) => {
  const content = useSelector(state => state.world.content)
  const choices = Object
    .keys(props.settings.handler_settings.target_bundles)
    .map((bundle) => `${props.settings.target_type}--${bundle}`);
  const options = {};

  choices.forEach(key => {
    Object.assign(options, content[key]);
  });

  const handleFieldChange = (event) => {
    const { value } = event.target;
    const target = event.target.getAttribute('data-field-index');
    const val = {
      id: value,
      type: options[value].type
    };
    console.log('handleFieldChange');
    console.log(val);
    props.handleFieldChange(val, target);
  };

  return (
    <select
      name={props.name}
      id={props.name}
      onChange={handleFieldChange}
      data-field-index={props.index}
    >
      { Object.keys(options).map( key => (
        <option
          value={key}
          selected={ props.value == key ? true : false }
        >
          {options[key].title}
        </option>
      )) }
    </select>
  )

  return (
    <input
      className="form-control"
      data-field-index={props.index}
      name={props.name}
      type="text"
      value={props.values}
      placeholder={props.settings.placeholder}
      onChange={handleFieldChange}
    />
  )
};

const TextWithSummary = (props) => {

  const defaultData = {
    format: "basic_html",
    summary: "",
    value: "",
  };
  const fieldData = { ...defaultData, ...props.value };

  const handleFieldChange = (event) => {
    const { value, name } = event.target;
    const target = event.target.getAttribute('data-field-index');
    const val = { ...fieldData };

    switch (name) {
      case props.name:
        val.value = value;
        break;

      case props.name + "summary":
        val.summary = value;
        break;
    }
    props.handleFieldChange(val, target);
  };

  return (
    <>
      <textarea
        className="form-control"
        data-field-index={props.index}
        name={props.name + "summary"}
        rows='3'
        cols="30"
        value={fieldData.summary}
        placeholder={props.settings.placeholder}
        onChange={handleFieldChange}
      />
      <textarea
        className="form-control"
        data-field-index={props.index}
        name={props.name}
        rows={props.settings.rows}
        cols="30"
        value={fieldData.value}
        placeholder={props.settings.placeholder}
        onChange={handleFieldChange}
      />
    </>
  )
};

const DefaultField = (props) => (
  <div>{props.name}</div>
);

const DrupalFormField = (props) => {
  let fieldRender = "";

  switch (props.type) {
    case "text_textarea_with_summary":
      fieldRender = <TextWithSummary />
      break;
    case "string_textfield":
      fieldRender = <StringTextfield />
      break;
    case "entity_reference_autocomplete":
      fieldRender = <EntityReferenceAutocomplete />
      break;
    default:
      fieldRender = <DefaultField />
      break;
  }

  return (
    <div className="form-group">
      <label>{props.label ? props.label : props.name}</label>
      <FieldGroup field={fieldRender} {...props} />
    </div>
  );
};

export default DrupalFormField;
