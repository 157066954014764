import React, { useState } from "react";
import LoginForm from '../LoginForm';
import NodeReadWrite from "./NodeReadWrite";

const ArticleHome = () => {

  return (
    <>
      <LoginForm />
      <NodeReadWrite />
    </>
    );

};

export default ArticleHome;
