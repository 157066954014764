let env = "prod"

const env_info = {
  ENV: env,
  API_ROOT: 'jsonapi',
  JSON_DIRECTORY : "json",
  SERVER : "http://writer.thewebticket.com",
  DRUPAL_7_SERVER: "//scad.edu"
}

// Set evn_info based on env.
switch(env) {
    case "prod":
        env_info.SERVER = "http://writer.thewebticket.com"
        env_info.DRUPAL_7_SERVER = "//scad.edu"
        break;

    case "stage":
        env_info.SERVER = "https://scadappstg.scaddev.com"
        env_info.DRUPAL_7_SERVER = "//scadstg.prod.acquia-sites.com"
        break;

    case "dev":
        env_info.SERVER = "https://scadappdev.scaddev.com"
        env_info.DRUPAL_7_SERVER = "//edudev.scaddev.com"
        break;

    case "local":
        env_info.SERVER = "http://writing-tool.dd:8083"
        env_info.JSON_DIRECTORY = "json"
        env_info.DRUPAL_7_SERVER = "//http://scad.prod.dd:8083"
        break;

    default:
        env_info.JSON_DIRECTORY = "json"
}

export default env_info
