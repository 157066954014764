import C from '../../constants'

const world = (state = {content:{}}, action) => {

  switch (action.type) {

    case C.SET_WORLD:
      return {
        ...state,
        activeWorld: action.payload,
      }
    case C.SET_WORLD_CONTENT_LIST:
      let worldContent = {...state.content}

      action.payload.forEach(node => {
        if (worldContent[node.type] === undefined) {
          worldContent[node.type]={};
        }
        worldContent[node.type][node.id] = node;
      });

      return {
        ...state,
        content: worldContent,
      }

    default:
      return state
  }

}

export default world
